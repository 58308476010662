import React, { useState, useEffect } from "react";

const Page404 = () => {
  return (
    <div className="page404">
      <h2>Nothing Found Here</h2>
      <h3>
        Please scan the QR code on your table or approach your phone to the NFC
        reader in order to see and pay your bill.
      </h3>
    </div>
  );
};

export default Page404;
