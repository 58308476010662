import React, { Component } from "react";
import { withRouter } from "../../common/with-router";

class AddTip extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);

    this.addtip = this.addtip.bind(this);
    this.handleCustomAmount = this.handleCustomAmount.bind(this);

    this.state = {
      tip: 0,
      tip_percentage: 0,
      message: "",
      total: 0,
      customAmount: 0,
      active: "notip",
    };
  }

  componentDidMount() {
    var orderId = this.props.router.params.id;
    this.setState({ orderId });
    this.state.total = this.props.y;
  }

  addtip(tip_perc) {
    this.state.total = this.props.y + (this.props.y * tip_perc) / 100;
    this.state.tip = (this.props.y * tip_perc) / 100;
    this.props.MasterTotal2(this.state.total);
    this.props.TheTip(this.state.tip);
    this.setState({
      tip_percentage: tip_perc,
    });
  }

  handleCustomAmount(value) {
    this.state.total = this.props.y + value;
    this.props.MasterTotal2(this.state.total);
    this.props.TheTip(value);
    this.setState({
      tip: this.state.value,
    });
  }

  someFunct(name) {
    this.setState({ active: name });
  }

  render() {
    var disableTip = false;

    const ToggleClass = () => {
      //  setActive(!isActive);
    };

    return (
      // <div className={"tipz" + (disableTip === true ? " disableTip" : "")}>
      <div className="tipz">
        <button
          value="0"
          /*  onClick={() => this.addtip(0)} */
          className={
            this.state.active === "notip" ? "tip noTip active" : "tip noTip"
          }
          onClick={() => this.someFunct("notip") + this.addtip(0)}
        >
          No Tip
        </button>
        <button
          value="5"
          className={
            this.state.active === "first"
              ? "tip btn btn-sm active"
              : "tip btn btn-sm"
          }
          onClick={() => this.someFunct("first") + this.addtip(5)}
          /* onClick={() => this.addtip(5)} */
        >
          5%
          <div>€{parseFloat((this.props.y * 5) / 100).toFixed(2)}</div>
        </button>
        <button
          value="7"
          className={
            this.state.active === "second"
              ? "tip btn btn-sm active"
              : "tip btn btn-sm"
          }
          onClick={() => this.someFunct("second") + this.addtip(7)}
        >
          7% <div>€{parseFloat((this.props.y * 7) / 100).toFixed(2)}</div>
        </button>
        <button
          value="10"
          className={
            this.state.active === "third"
              ? "tip btn btn-sm active"
              : "tip btn btn-sm"
          }
          onClick={() => this.someFunct("third") + this.addtip(10)}
        >
          10% <div>€{parseFloat((this.props.y * 10) / 100).toFixed(2)}</div>
        </button>
        <form
          className={
            this.state.active === "fourth" ? " tipform active" : " tipform"
          }
          onClick={() => this.someFunct("fourth")}
          /*  className="form1 tipform"  */
          noValidate
          autoComplete="off"
        >
          <input
            type="number"
            placeholder="Other"
            inputMode="numeric"
            step="0.01"
            min="0"
            /*             pattern="^\d*(\.\d{0,2})?$"
             */
            onChange={(e) =>
              e.target.value
                ? this.handleCustomAmount(parseFloat(e.target.value))
                : this.handleCustomAmount(parseFloat(0))
            }
          ></input>
          <span className="euroInInputSmall">€</span>
          {/* <button
            className="tip btn btn-sm"
            type="submit"
            onClick={() => this.onSubmit()}
          >
            OK
          </button> */}
        </form>
      </div>
    );
  }
}

export default withRouter(AddTip);
