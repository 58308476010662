import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import PaymentButton from "../items/PaymentButton.component";
import AddTip from "../AddTip.component";

class Counter extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props);

    this.incrementCount = this.incrementCount.bind(this);
    this.decrementCount = this.decrementCount.bind(this);
    this.incrementCount2 = this.incrementCount2.bind(this);
    this.decrementCount2 = this.decrementCount2.bind(this);
    this.handleClickPayMyItems = this.handleClickPayMyItems.bind(this);
    this.backFromPayMyItems = this.backFromPayMyItems.bind(this);

    this.state = {
      count: 2,
      count2: 1,
      clickedPayMyItems: false,
      backFromPayMyItems: false,
      payButtonAmount: this.props.MasterTotal,
    };
  }

  componentDidMount() {
    this.state.total = this.props.remainingAmount;
  }

  incrementCount() {
    this.setState({
      count: this.state.count + 1,
    });
  }
  decrementCount() {
    this.setState({
      count: this.state.count - 1,
    });
  }
  incrementCount2() {
    this.setState({
      count2: this.state.count2 + 1,
    });
  }
  decrementCount2() {
    this.setState({
      count2: this.state.count2 - 1,
    });
  }

  handleClickPayMyItems() {
    this.setState((prevState) => ({
      clickedPayMyItems: !prevState.clickedPayMyItems,
    }));
    this.props.DoNotShowBackButton(true);
    this.props.MasterTotal2(
      (this.state.total / this.state.count) * this.state.count2
    );
  }

  backFromPayMyItems() {
    this.setState((prevState) => ({
      clickedPayMyItems: !prevState.clickedPayMyItems,
    }));
    this.props.DoNotShowBackButton(false);
  }

  render() {
    //      const { currentTutorial2, tip, tip_percentage, amount } = this.state;
    const baseClass = "counter";
    const buttonBgState = this.state.count <= 0 ? "bg-red" : "bg-blue";

    return !this.state.clickedPayMyItems ? (
      <div>
        <div className="division">
          {/*}   <br></br>
         <h5>remaining:</h5>
          <div className="total"> {currentTutorial2.total + Number(tip_percentage) - currentTutorial2.paid_amount}€</div>
          <br></br>
           */}
          <div
            className={`${baseClass} c1 ${buttonBgState}`}
            data-foo={this.state.count}
          >
            <h1>
              divide by
              <span className="highlight"> {this.state.count} </span>
              persons
            </h1>
            {this.state.count > 2 ? (
              <button type="button" onClick={this.decrementCount}>
                -
              </button>
            ) : (
              <button type="button" disabled>
                -
              </button>
            )}
            {this.state.count < 50 ? (
              <button type="button" onClick={this.incrementCount}>
                +
              </button>
            ) : (
              <button type="button" disabled>
                +
              </button>
            )}
          </div>
          <div
            className={`${baseClass} ${buttonBgState}`}
            data-foo={this.state.count2}
          >
            <h1>
              paying for
              <span className="highlight"> {this.state.count2} </span>
              person/s
            </h1>
            {this.state.count2 > 1 ? (
              <button type="button" onClick={this.decrementCount2}>
                -
              </button>
            ) : (
              <button type="button" disabled>
                -
              </button>
            )}
            {this.state.count2 < this.state.count - 1 ? (
              <button type="button" onClick={this.incrementCount2}>
                +
              </button>
            ) : (
              <button type="button" disabled>
                +
              </button>
            )}
          </div>

          {/*  <h5>to pay: </h5>
        <div className="total">
          {" "}
          {(
            (this.props.MasterTotal / this.state.count) *
            this.state.count2
          ).toFixed(2)}{" "}
          €
        </div> */}
          {/*         <AddTip
          MasterTotal2={this.props.MasterTotal2}
          TheTip={this.props.TheTip}
          y={this.props.remainingAmount}
        ></AddTip> */}
        </div>
        <button
          className="m-3 btnpay btn-sm"
          onClick={this.handleClickPayMyItems}
        >
          Pay &nbsp;
          {(
            (this.state.payButtonAmount / this.state.count) *
            this.state.count2
          ).toFixed(2)}{" "}
          €
        </button>
      </div>
    ) : this.state.clickedPayMyItems ? (
      <div>
        <button
          className="m-3 btnbk btn-sm clearhere"
          onClick={this.backFromPayMyItems}
        >
          back
        </button>{" "}
        <div className="tipTitle">Add Tip?</div>
        <AddTip
          MasterTotal2={this.props.MasterTotal2}
          TheTip={this.props.TheTip}
          y={(this.state.total / this.state.count) * this.state.count2}
        ></AddTip>
        <PaymentButton
          MasterTotal={
            this.props.MasterTotal
            // (this.state.total / this.state.count) * this.state.count2
          }
          SplitMethod="Divide"
          billTotal={this.props.billTotal}
          remainingAmount={this.props.remainingAmount}
        ></PaymentButton>
      </div>
    ) : (
      <div></div>
    );
  }
}
export default withRouter(Counter);
