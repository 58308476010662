import React, { Component } from "react";

class Checkbox extends Component {
  handleChange = () => {
    const {
      orderId,
      onCheckboxChange,
      calculateTotal,
      value,
      isChecked,
      ItemDescription,
      id,
    } = this.props;
    onCheckboxChange(orderId, !isChecked);
    calculateTotal(value, !isChecked, ItemDescription, id, orderId);
  };

  render() {
    const { isChecked } = this.props;

    return (
      <div className="inlinediv">
        <input
          type="checkbox"
          className="checkbox"
          checked={isChecked}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default Checkbox;
