import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import OrderItemDataService from "../../../services/orderitem.service";
import Checkbox from "../items/Checkbox.component";
import PaymentButton from "../items/PaymentButton.component";
import AddTip from "../AddTip.component";

class ChooseThings2 extends Component {
  state = {
    orderitems: [],
    checkedItems: {},
    clickedPayMyItems: false,
    status: false,
    total: 0,
    toPay: 0,
    arr: [],
    payitemsnumber: 0,
    billTotal: this.props.MasterTotal,
    backFromPayMyItems: false,
  };

  constructor(props) {
    super(props);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.handleClickPayMyItems = this.handleClickPayMyItems.bind(this);
    this.backFromPayMyItems = this.backFromPayMyItems.bind(this);
  }

  componentDidMount() {
    this.retrieveTutorials(this.props.oID);
  }

  retrieveTutorials(id) {
    OrderItemDataService.getAll(id)
      .then((response) => {
        this.setState({
          orderitems: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  calculateTotal(input, checked, desc, id, orderId) {
    let { total } = this.state;
    if (checked) {
      total += input;
      this.state.payitemsnumber += 3;
      // append new value to the array
      this.state.arr.push(orderId, desc, input);
    } else {
      total -= input;
      this.state.payitemsnumber -= 3;
      const index = this.state.arr.indexOf(id);
      console.log(index);
      if (index > -1) {
        // only splice array when item is found
        this.state.arr.splice(index, 3); // 2nd parameter means remove 2 item only
      }
    }
    console.log("eyo arr", this.state.arr, " kai ", this.state.payitemsnumber);

    this.setState({
      total,
    });
  }

  sendAmountToPay() {
    alert("pay divided amount, " + this.state.toPay);
  }

  handleCheckboxChange = (orderId, isChecked) => {
    this.setState((prevState) => ({
      checkedItems: {
        ...prevState.checkedItems,
        [orderId]: isChecked,
      },
    }));
  };

  handleClickPayMyItems() {
    this.setState((prevState) => ({
      clickedPayMyItems: !prevState.clickedPayMyItems,
    }));
    this.props.DoNotShowBackButton(true);
    this.props.MasterTotal2(this.state.total);
  }

  backFromPayMyItems() {
    this.setState((prevState) => ({
      clickedPayMyItems: !prevState.clickedPayMyItems,
    }));
    this.props.DoNotShowBackButton(false);
  }

  render() {
    const { orderitems, checkedItems } = this.state;

    return !this.state.clickedPayMyItems ? (
      <div>
        <div className="inlinediv">
          <ul className="list-group-chooseThing">
            {orderitems &&
              orderitems.map((orderitem, index) =>
                orderitem.paid != "paid" ? (
                  <li className={"list-group-item-chooseThing"} key={index}>
                    <Checkbox
                      calculateTotal={this.calculateTotal}
                      value={orderitem.ItemPrice}
                      orderId={orderitem.id}
                      id={index}
                      ItemDescription={orderitem.ItemDescription}
                      isChecked={!!checkedItems[orderitem.id]}
                      onCheckboxChange={this.handleCheckboxChange}
                    />
                    &nbsp; {orderitem.quantity}&nbsp;{" "}
                    {orderitem.ItemDescription} &nbsp;&nbsp;€
                    {orderitem.ItemPrice.toFixed(2)}
                  </li>
                ) : (
                  <li className={"list-group-item paid-li"} key={index}>
                    &nbsp; {orderitem.quantity}&nbsp;{" "}
                    {orderitem.ItemDescription} &nbsp;&nbsp;€
                    {orderitem.ItemPrice.toFixed(2)}
                  </li>
                )
              )}
            {/*             <h5>to pay: </h5>
                          <p> {this.state.total}</p> */}
          </ul>
        </div>
        {this.state.total != 0 ? (
          <button
            className="m-3 btnpay btn-sm"
            onClick={this.handleClickPayMyItems}
          >
            Pay &nbsp;
            {this.state.total.toFixed(2)} €
          </button>
        ) : (
          <button className="m-3 btnpay btn-sm" disabled>
            Pay &nbsp;
            {this.state.total.toFixed(2)} €
          </button>
        )}
      </div>
    ) : this.state.clickedPayMyItems ? (
      <div>
        <button
          className="m-3 btnbk btn-sm clearhere"
          onClick={this.backFromPayMyItems}
        >
          back
        </button>{" "}
        <div className="tipTitle">Add Tip?</div>
        <AddTip
          MasterTotal2={this.props.MasterTotal2}
          TheTip={this.props.TheTip}
          y={this.state.total}
        ></AddTip>
        <PaymentButton
          MasterTotal={this.props.MasterTotal}
          SplitMethod="ChooseYourItems"
          ButtonTxt=""
          arr={this.state.arr}
          payitemsnumber={this.state.payitemsnumber}
          billTotal={this.props.billTotal}
          remainingAmount={this.props.remainingAmount}

          // oID={oID}
          // msg=""
        ></PaymentButton>
      </div>
    ) : (
      <div></div>
    );
  }
}
export default withRouter(ChooseThings2);
