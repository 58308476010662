import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import Menu from "../../Menu";

class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.showMenuModal = this.showMenuModal.bind(this);
    this.hideMenuModal = this.hideMenuModal.bind(this);

    this.state = {
      showMenu: false,
    };
  }

  showMenuModal = () => {
    this.setState({ showMenu: true });
  };

  hideMenuModal = () => {
    this.setState({ showMenu: false });
  };

  render() {
    const { id } = this.state;

    return (
      <div>
        {this.state.showMenu ? (
          <Menu
            show={this.state.showMenu}
            handleClose={this.hideMenuModal}
            oID={this.props.oID}
            menuPath={this.props.menuPath}
          ></Menu>
        ) : (
          <div></div>
        )}

        <button className="menu-btn" onClick={this.showMenuModal}>
          SHOW MENU
          {this.props.ButtonTxt}
        </button>
      </div>
    );
  }
}

export default withRouter(MenuButton);
